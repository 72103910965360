@import "../../../../styles/variables";

.module-a-library-card {
  padding: $component-padding + 20px $component-padding $component-padding
    $component-padding;
  display: block;
  position: relative;

  .tag {
    color: red;
    display: block;
    position: absolute;
    top: $component-padding;
    left: $component-padding;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .badgeA {
    background-color: green;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }
  .badgeB{
    background-color:  yellow;
    color: black;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }
  .badgeC {
    background-color: red;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }
}
