@import "./variables";

html {
  background-color: $window-Background;
}

html,
body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

body {
  background: $page-Background;
  color: $page-Foreground;
}

#root,
.h-app-container {
  height: 100%;
}

.h-app-container {
  display: flex;
  flex-direction: column;
  transition: filter 0.3s, background 0.5s;

  &.has-cover {
    filter: blur(10px);
  }

  &.signed-out {
    flex-direction: row;
    justify-content: center;
    background: $page-signed-out-Background;
  }
}

.hide-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  .badgeA {
    background-color: green;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }
  .badgeB{
    background-color:  orange;
    color: black;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }
  .badgeC {
    background-color: red;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }
  .badgeD {
    background-color: lightblue;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
  }