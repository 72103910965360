.invoice-modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .h-app-input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .field-group {
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 0;

    >* {
      flex: 1;
      margin: 0;
    }
  }

  .title {
    background-color: white;
    color: white;
    margin-left: 24px;
    padding-left: 280px;
    border-radius: 6px;
  }

  .float-parent-element {
    width: 50%;
  }

  .float-child-element {
    float: left;
    width: 50%;
  }

  .invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    // color: #555;
  }

  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
    border-collapse: collapse;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
    
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: right;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
    
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
    
  }

  .invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    border-right: solid 1px #ddd; 
    border-left: solid 1px #ddd;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
    
    
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
    border-right: solid 1px #ddd; 
    border-left: solid 1px #ddd;
  }
  .invoice-box table tr.total td {
    border-bottom: 1px solid #eee;
    border-right: solid 1px #ddd; 
    border-left: solid 1px #ddd;
  }
  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
    }

    .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
      
    }
  }

  @media print {
    .invoice-box {
      max-width: unset;
      box-shadow: none;
      border: 0px;
    }
  }
}