@import '../../../../styles/variables';

.product-item {
  padding: $page-padding;
}

.item-header {
  background-color: #ddd;
  padding: 20px;
  border-radius: 8px;

  h1 {
    margin: 0;
  }
}
div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: #ddd;
  padding: 20px;
  border-radius: 8px;
  z-index: 2;
  h1 {
    margin: 0;
  }
}
