.app-logo {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  border-radius: 5px;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 30px;
    margin-right: 10px;
  }
}
