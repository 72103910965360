@import "../../../../styles/_variables";

.order-item {
  padding: $page-padding;

  h1 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
  }
}
