@import "../../../../styles/variables";

.client-item {
  & > h1 {
    margin: $page-padding;
    padding: 0;
    color: $app-primary;
    font-size: 24px;
    font-weight: 500;
  }
}
