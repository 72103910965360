.product-modal {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .h-app-input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .field-group {
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 0;

    > * {
      flex: 1;
      margin: 0;
    }
  }

  .title {
    background-color: #1d84b5;
    color: white;
    padding: 4px 16px;
    border-radius: 6px;
  }
  .float-parent-element { 
    width: 50%; 
  } 
  .float-child-element { 
    float: left; 
    width: 50%; 
  }
}
