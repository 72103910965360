@import '../../../../styles/variables';

.module-p-library-card {
  padding: $component-padding $component-padding $component-padding
    $component-padding;
  display: block;
  position: relative;
  margin: 10px 5px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  .icon-container {
    background-color: $button-primary-Background;
    color: $button-primary-Foreground;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  h3 {
    margin: 0;
  }

  .tag {
    display: block;
    position: absolute;
    left: $component-padding;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
  

}
