@import "../../../../styles/variables";

.module-a-library-card {
  padding: $component-padding + 20px $component-padding $component-padding
    $component-padding;
  display: block;
  position: relative;
.module-p-library-card {
  padding: $component-padding $component-padding $component-padding
    $component-padding;
  display: block;
  position: relative;
  margin: 10px 5px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  gap: 8px;
}
  .tag {
    color: red;
    display: block;
    position: absolute;
    top: $component-padding;
    left: $component-padding;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
