@import '../../styles/variables';

.h-app-register {
  align-self: center;
  justify-self: center;
  max-width: 540px;
  padding: $page-padding;
  width: 100%;
  text-align: right;
  box-shadow: $layer-shadow;
  border-radius: $box-border-radius;
  background: $page-Background;
  border-top: solid 10px $app-primary;
  overflow-Y: scroll;  
  height: 75vh;


  .logo-container {
    max-width: 60%;
    margin: 0 auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    > * {
      flex: 1;
    }
  }

  .h-app-logo {
    margin-bottom: $page-padding + 15px;
  }

  .step {
    background: $item-Background;
    color: $item-Foreground;
    border: solid 3px transparent;
    padding: $component-padding calc($component-padding * 2);
    box-shadow: $layer-shadow;
    border-radius: $box-border-radius;
    margin-bottom: $page-padding;
    transition: border 0.2s;
  }

  &.error-highlight {
    .credentials {
      border: solid 3px $error-Background;
    }
  }

  &.in {
    opacity: 0;
    animation: scale-pop-in 0.5s 0.5s forwards;
  }

  &.error {
    animation: shake 0.5s forwards linear;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
