@import '../../styles/variables';

.table-container {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;

  .admin-table {
    border-collapse: collapse;
    width: 100%;

    .admin-table__drag {
      position: absolute;
      width: 100%;
      text-indent: 50px;
      border: 1px solid #f1f1f1;
      z-index: 10;
      cursor: grabbing;

      opacity: 1;
    }

    thead {
      th {
        height: 25px;
        font-weight: bold;
        padding: 10px;
        user-select: none;
        text-align: left;
      }
    }

    tbody {
      tr {
        td {
          cursor: grab;
          padding: 10px;
          user-select: none;
          border-top: 1px solid whitesmoke;
          transition: all 0.2s ease-in-out;
        }
      }

      tr:nth-child(odd) {
        background-color: #00000010;
      }

      tr.dragging-over-first {
        border-bottom: 2px solid $app-primary;
      }

      tr.dragging-over {
        border-bottom: 2px solid $app-primary;
      }

      tr.dragging {
        background: #fff;

        td {
          color: $app-primary;
          cursor: grabbing;
        }
      }
    }
  }
}