@import "../../../../styles/_variables";

.order-item {
  padding: $page-padding;

  h1 {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
  }
}
.card-body{
    margin: 0px;
    border-radius: 4px;
    box-shadow: 4px 4px 4px 4px lightgray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .field-wrap{
        overflow-wrap: "break-word";
        font-size: 18;
        font-weight: 600;
        margin-top: 4px;
    }
    span{
        color: $app-primary;
    }
    .action-container{
        height: 100%;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}