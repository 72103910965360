@import "../../styles/variables";

.h-app-user-account {
  $avatarSize: 40px;
  $avatarGutter: 5px;

  border-bottom: solid 1px $horizontalRule-Background;
  margin: 0 5px;
  display: flex;
  flex-direction: row;
  height: $avatarSize + calc($avatarGutter * 2);
  padding-bottom: $component-padding;

  .active {
    background: $sideNav-item-active-Background;
    color: $sideNav-item-active-Foreground;
    box-shadow: $layer-shadow;
  }

  .h-app-clickable {
    border-radius: 5px;
    overflow: hidden;

    &:not(.active):hover {
      background: $sideNav-item-hover-Background;
      color: $sideNav-item-hover-Foreground;
    }
  }

  .user {
    flex: 1;
    position: relative;

    button {
      height: $avatarSize + calc($avatarGutter * 2);
    }

    .avatar {
      position: absolute;
      top: $avatarGutter;
      left: $avatarGutter;
      border-radius: 50%;
      height: $avatarSize;
      width: $avatarSize;
      color: rgba(0, 0, 0, 0.6);
      text-transform: uppercase;
      font-weight: 600;
      line-height: $avatarSize;
      font-size: 18px;
    }

    .name {
      font-weight: 100;
      font-size: 12px;
      position: absolute;
      left: $avatarSize + calc($avatarGutter * 2);
      top: $avatarGutter + 6px;
      right: $avatarGutter;
      text-align: left;
    }

    .email {
      font-weight: 100;
      font-size: 11px;
      position: absolute;
      left: $avatarSize + calc($avatarGutter * 2);
      right: $avatarGutter;
      bottom: $avatarGutter + 6px;
      text-align: left;
    }
  }

  .settings,
  .sign-out {
    flex: 0 0 30px;
    display: flex;
    align-items: stretch;

    button {
      font-size: 18px;
      display: flex;
      align-items: center !important;
      justify-content: center;
    }
  }
}
