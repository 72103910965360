// Colors
$app-primary: #1D84B5;
$app-primary-light: rgb(20, 195, 255);
$app-primary-dark: rgb(0, 145, 205);
$window-Background: #fff;
$page-Background: #ddd;
$page-Foreground: #535353;
$page-signed-out-Background: radial-gradient(#ccc, #888, #333);
$sideNav-Background: transparent;
$sideNav-item-Background: transparent;
$sideNav-item-Foreground: rgba(0, 0, 0, 0.9);
$sideNav-item-hover-Background: rgba(255, 255, 255, 0.8);
$sideNav-item-hover-Foreground: inherit;
$sideNav-item-active-Background: $app-primary;
$sideNav-item-active-Foreground: #fff;
$toolbar-Background: transparent;
$toolbar-Foreground: inherit;
$toolbar-item-icon-Foreground: $app-primary-dark;
$horizontalRule-Background: rgba(3, 2, 2, 0.1);
$scrollbar-Background: rgba(0, 0, 0, 0.2);
$scrollbar-hover-Background: rgba(0, 0, 0, 0.3);
$library-Background: #fff;
$library-Foreground: #333;
$library-item-Background: transparent;
$library-item-Foreground: inherit;
$library-item-hover-Background: rgba(0, 175, 239, 0.2);
$library-item-hover-Foreground: inherit;
$library-item-active-Background: $app-primary;
$library-item-active-Foreground: #fff;
$item-index-Background: transparent;
$item-index-Foreground: rgba(0, 0, 0, 0.3);
$item-Background: #fff;
$item-Foreground: inherit;
$shadow-Color: rgba(50, 50, 50, 0.4);
$field-instructions-Foreground: rgba(0, 0, 0, 0.4);
$field-instructions-Background: #f5f5f5;
$field-instructions-error-Foreground: rgb(230, 0, 0);
$breadcrumb-Background: transparent;
$breadcrumb-Foreground: $app-primary-dark;
$breadcrumb-hover-Background: rgba(255, 255, 255, 0.8);
$breadcrumb-hover-Foreground: $app-primary;
$search-box-Background: #fff;
$search-box-Foreground: #333;
$search-box-hover-Background: #fff;
$search-box-hover-Foreground: inherit;
$search-box-hover-Border: rgba(0, 175, 239, 0.2);
$search-box-focus-Background: #fff;
$search-box-focus-Foreground: #000;
$search-box-focus-Border: $app-primary;
$paging-Background: transparent;
$paging-selected-Background: $app-primary;
$paging-selected-Foreground: #fff;
$field-readonly-Foreground: rgba(0, 0, 0, 0.87);
$field-readonly-Border: rgba(0, 0, 0, 0.08);
$button-primary-Background: $app-primary;
$button-primary-Foreground: #fff;
$button-primary-hover-Background: $app-primary-light;
$button-primary-hover-Foreground: #fff;
$button-primary-disabled-Background: #ddd;
$button-primary-disabled-Foreground: #fff;
$button-primary-focus-Border: #fff;
$button-secondary-Background: transparent;
$button-secondary-Foreground: inherit;
$button-secondary-hover-Background: rgba(255, 255, 255, 0.8);
$button-secondary-hover-Foreground: inherit;
$button-secondary-disabled-Background: transparent;
$button-secondary-disabled-Foreground: rgba(0, 0, 0, 0.2);
$button-seconday-focus-Border: #fff;
$modal-window-Background: #eee;
$modal-content-Background: #fff;
$error-Background: #f44336;
$warning-Background: #ffa726;
$info-Background: #66bb6a;
// Sizing
$sideNav-width: 250px;
$page-gutter: 20px;
$page-padding: 20px;
$component-gutter: 5px;
$component-padding: 10px;
$library-width: 320px;
$field-gutter: 30px;
$form-padding: $page-padding;
$app-logo-margin: 15px;
$button-padding: 10px;
// Animations
$animation-fade-in: fade-in 0.2s forwards;
// Other layout
$layer-shadow: 0 3px 7px $shadow-Color;
$box-border-radius: 5px;
