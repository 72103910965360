@import '../../styles/variables';

.h-app-logo {
  flex: 0 0 68px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: $app-logo-margin;
  height: 68px;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }

  .logo {
    width: 100%;
  }
}
