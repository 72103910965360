@import '../../styles/variables';

.h-app-auto-complete {
  width: 100%;
  margin-bottom: calc($field-gutter / 2) !important;
  margin-top: calc($field-gutter / 2) !important;

  .MuiInputBase-root {
    height: fit-content;
    padding-top: 0px !important;
  }

  label {
    top: -4px !important;
  }
}

.h-app-select {
  width: 100%;
  margin-bottom: calc($field-gutter / 2) !important;
  margin-top: calc($field-gutter / 2) !important;

  div {
    padding: 10px 14px;
  }
}

.h-app-input-field {
  display: block !important;
  margin-bottom: calc($field-gutter / 2) !important;
  margin-top: calc($field-gutter / 2) !important;
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .MuiInputBase-root {
    width: 100% !important;
  }

  .MuiInputBase-adornedStart {
    svg {
      margin-right: $component-padding;
    }

    &.Mui-focused {
      svg {
        color: $app-primary;
      }
    }
  }

  fieldset {
    transition: border-color 0.2s;
  }

  &.readonly * {
    cursor: default !important;

    fieldset {
      border-color: $field-readonly-Border !important;
    }
  }
}

.h-app-autocomplete-field {
  &.readonly * {
    cursor: default !important;

    fieldset {
      border-color: $field-readonly-Border !important;
    }
  }
}

.h-app-date-field {
  &.readonly {
    .MuiInputAdornment-root {
      color: $field-readonly-Border !important;
    }
  }
}

.h-app-checkbox {
  .MuiCheckbox-root {
    margin-bottom: 1px;
  }

  &.readonly {
    .MuiFormControlLabel-label {
      color: $field-readonly-Foreground !important;
    }

    .Mui-checked {
      color: $app-primary !important;
    }
  }
}

.h-app-radio-buttons {
  &.readonly {

    label,
    .MuiFormControlLabel-label {
      color: $field-readonly-Foreground !important;
    }

    .Mui-checked {
      color: $app-primary !important;
    }
  }
}

.h-app-field-instruction-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-bottom: dashed 1px $horizontalRule-Background;

  .instruction-field {
    flex: 1;
    margin-right: $field-gutter;

    &.flex-row {
      display: flex;
      flex-direction: row;

      &>* {
        flex: 1;

        &:not(:last-child) {
          margin-right: $component-padding;
        }
      }
    }
  }

  .instruction-text {
    flex: 1;
    padding: 0 $field-gutter;
    background: $field-instructions-Background;
    color: $field-instructions-Foreground;
    font-size: 14px;
    align-self: stretch;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .error {
      display: block;
      color: $field-instructions-error-Foreground;
      margin-top: $component-gutter;
      font-size: 12px;
    }
  }

  &:first-of-type {
    .instruction-text {
      border-top-right-radius: 7px;
    }
  }

  &:last-of-type {
    border-bottom: none;

    .instruction-text {
      border-bottom-right-radius: 7px;
    }
  }
}