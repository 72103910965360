@import '../../styles/variables';

.h-app-side-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: $sideNav-width;
  box-sizing: border-box;
  background: $sideNav-Background;
  animation: side-nav-in 0.2s forwards ease-in-out;

  padding: 10px;

  .h-app-scrollable-content {
    padding-top: $component-padding;
  }
}

.h-app-side-nav-link {
  display: block !important;
  background: $sideNav-item-Background;
  border-radius: 5px !important;
  margin: $component-gutter;
  display: block;
  text-decoration: none !important;
  color: $sideNav-item-Foreground;
  overflow: hidden;

  &:hover {
    background: $sideNav-item-hover-Background;
    color: $sideNav-item-hover-Foreground;
  }

  button {
    padding: 10px !important;
    font-size: 16px !important;
  }

  svg {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    width: 20px;
  }

  &.active {
    background: $sideNav-item-active-Background;
    color: $sideNav-item-active-Foreground;
    box-shadow: $layer-shadow;
  }
}

@keyframes side-nav-in {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
